import
{
	createNewMessage
} from './helper';

const chat = (state = [], action) =>
{
	switch (action.type)
	{
		case 'ADD_NEW_USER_MESSAGE':
		{
			const { chatMessage } = action.payload;
			let message

			if (chatMessage.action) {
				message = {
					type : 'message',
					text: '',
					time : Date.now(),
					name: 'Me',
					sender: 'client',
					picture: undefined,
					action: {
						type: 'share_video',
						video: chatMessage.action.video,
						posterName: chatMessage.action.posterName
					}
				}
			} else {
				message = createNewMessage(chatMessage.text, 'client', 'Me', undefined);
			}

			return [ ...state, message ];
		}

		case 'ADD_NEW_RESPONSE_MESSAGE':
		{
			const { message } = action.payload;

			return [ ...state, message ];
		}

		case 'ADD_CHAT_HISTORY':
		{
			const { chatHistory } = action.payload;

			return [ ...state, ...chatHistory ];
		}

		case 'CLEAR_CHAT':
		{
			return [];
		}

		default:
			return state;
	}
};

export default chat;
