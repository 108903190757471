import React from 'react';
import { connect } from 'react-redux';
import {
	participantListSelector,
	makePermissionSelector
} from '../../Selectors';
import { permissions } from '../../../permissions';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { FormattedMessage } from 'react-intl';
import videos from './source'
import VimeoModal from '../../VimeoModal'
import { withRoomContext } from '../../../RoomContext';
import * as meActions from '../../../actions/meActions';
import * as toolareaActions from '../../../actions/toolareaActions';

console.log(videos)

const styles = (theme) =>
	({
		root :
		{
			width     : '100%',
			overflowY : 'auto',
			padding   : theme.spacing(1)
		},
		list :
		{
			listStyleType   : 'none',
			padding         : theme.spacing(1)
		},
		listheader :
		{
			fontWeight : 'bolder'
		},
		listItem :
		{
			width                : '100%',
			overflow             : 'hidden',
			cursor               : 'pointer',
			padding         : theme.spacing(2.4),
			boxShadow       : '0 2px 5px 2px rgba(0, 0, 0, 0.2)',
			backgroundColor : 'rgba(255, 255, 255, 1)',
			marginBottom: '1rem',
			fontSize: '0.875rem',
	    textAlign: 'center',
	    fontWeight: 500,
	    letterSpacing: '0.02857em',
	    textTransform: 'uppercase',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			paddingLeft: '4em'
		},
		title :
		{
			fontSize: '1.2rem',
	    textAlign: 'center',
	    fontWeight: 600,
	    letterSpacing: '0.02857em',
	    textTransform: 'uppercase',
			marginBottom: '0.4em',
			fontFamily: 'sans-serif'
		},
		description :
		{
			fontSize: '0.8rem',
	    textAlign: 'center',
	    fontWeight: 400,
	    letterSpacing: '0.02857em',
	    textTransform: 'uppercase',
			color: 'rgba(0, 0, 0, 0.54)',
			fontFamily: 'sans-serif'
		},
		modalContainer: {
			display: 'flex',
	    alignItems: 'center',
	    justifyContent: 'center'
		},
		modal :
		{
			backgroundColor: 'rgba(255, 255, 255, 1)',
			width: '640px',
		  border: 'none',
			outline: 'none'
		},
		btnClose :
		{
			 cursor:'pointer',
			 marginTop: '5px',
			 width: '20px',
			 objectFit: 'contain'
		},
		modalHeader : {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '0 1em'
		}
	});

class VideoList extends React.PureComponent
{
	constructor(props) {
		super(props)
		this.showVideoPopup = this.showVideoPopup.bind(this)
	}
	componentDidMount()
	{
		this.node.scrollTop = this.node.scrollHeight;
	}

	getSnapshotBeforeUpdate()
	{
		return this.node.scrollTop
			+ this.node.offsetHeight === this.node.scrollHeight;
	}

	componentDidUpdate(prevProps, prevState, shouldScroll)
	{
		if (shouldScroll)
		{
			this.node.scrollTop = this.node.scrollHeight;
		}
	}

	showVideoPopup(video) {
		this.props.roomClient.sharedVideo(video)

		this.props.roomClient.sendChatMessage({
			type : 'message',
			text: '',
			time : Date.now(),
			name: this.props.displayName,
			sender: 'response',
			picture: this.props.picture,
			action: {
				type: 'share_video',
				video,
				posterName: this.props.displayName
			}
		});

		this.props.changeTab('chat')
		this.props.sharedVideoSet(video)
	}

	render()
	{
		const {
			advancedMode,
			isModerator,
			participants,
			spotlights,
			selectedPeers,
			classes
		} = this.props;

		return (
			<div className={classes.root} ref={(node) => { this.node = node; }}>
				<ul className={classes.list}>
				{
					videos.map((video, i) =>
						<li className={classes.listItem} key={video.id} onClick={() => this.showVideoPopup(video)}>
							<span className={classes.title}>{video.name}</span>
							<span className={classes.description}>{video.description}</span>
						</li>
					)

				}
				</ul>

			{
				/*
				this.state.currentVideo &&
				<VimeoModal open={this.state.open} onClose={this.handleClose} closeParticipants={this.handleCloseParticipants} video={this.state.currentVideo}/>
				*/
			}
			</div>
		);
	}
}

VideoList.propTypes =
{
	advancedMode  : PropTypes.bool,
	isModerator   : PropTypes.bool.isRequired,
	participants  : PropTypes.array.isRequired,
	spotlights    : PropTypes.array.isRequired,
	selectedPeers : PropTypes.array.isRequired,
	classes       : PropTypes.object.isRequired
};

const makeMapStateToProps = () =>
{
	const hasPermission = makePermissionSelector(permissions.MODERATE_ROOM);

	const mapStateToProps = (state) =>
	{
		return {
			isModerator   : hasPermission(state),
			participants  : participantListSelector(state),
			spotlights    : state.room.spotlights,
			selectedPeers : state.room.selectedPeers,
			displayName   : state.settings.displayName,
			picture       : state.me.picture,
			canChat       : hasPermission(state)
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = (dispatch) =>
	({
		sharedVideoSet : (video) =>
		{
			dispatch(meActions.sharedVideo(video))
		},
		changeTab : (tabName) => {
			dispatch(toolareaActions.setToolTab(tabName));
		},
	});

const VideoListContainer = withRoomContext(
	connect(
		makeMapStateToProps,
		mapDispatchToProps,
		null,
		{
			areStatesEqual : (next, prev) =>
			{
				return (
					prev.room.spotlights === next.room.spotlights &&
					prev.room.selectedPeers === next.room.selectedPeers &&
					prev.me.roles === next.me.roles &&
					prev.peers === next.peers
				);
			}
		}
	)(withStyles(styles)(VideoList))
);

export default VideoListContainer;
