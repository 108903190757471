import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import isElectron from 'is-electron';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CookieConsent from 'react-cookie-consent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { connect } from 'react-redux';
import { withRoomContext } from '../RoomContext';
import qs from 'qs'
import * as errorActions from '../actions/errorActions';

const styles = (theme) =>
	({
		root :
		{
			display              : 'flex',
			width                : '100%',
			height               : '100%',
			backgroundColor      : 'var(--background-color)',
			backgroundImage      : `url(${window.config ? window.config.background : null})`,
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat'
		},
		dialogTitle :
		{
		},
		dialogPaper :
		{
			width                          : '30vw',
			padding                        : theme.spacing(2),
			[theme.breakpoints.down('lg')] :
			{
				width : '40vw'
			},
			[theme.breakpoints.down('md')] :
			{
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] :
			{
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] :
			{
				width : '90vw'
			}
		},
		logo :
		{
			display       : 'block',
			paddingBottom : '1vh'
		},
		loginButton :
		{
			position : 'absolute',
			right    : theme.spacing(2),
			top      : theme.spacing(2),
			padding  : 0
		},
		largeIcon :
		{
			fontSize : '2em'
		},
		largeAvatar :
		{
			width  : 50,
			height : 50
		},
		green :
		{
			color : 'rgba(0, 153, 0, 1)'
		},
		error :
		{
			display         : 'flex',
			alignItems      : 'center',
			height          : 40,
			padding         : 8,
			backgroundColor : '#f5035726',
			borderRadius    : 4,
			color           : '#f50357'
		}
	});

const DialogTitle = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
	root :
	{
		padding : theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogActions);

const ChooseRoom = ({
	roomClient,
	classes,
	loginError,
	setLoginError,
	location
}) =>
{
	const intl = useIntl();
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
	if (queryParams['error'] === 'true') {
		setLoginError(true)
	}

	const [ textChanged, setTextChanged ] = useState(false);

	const showError = loginError && !textChanged
	return (
		<div className={classes.root}>
			<Dialog
				open
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle>
						<Typography variant='h5'>Velkommen kursleder!</Typography>
					<hr />

				</DialogTitle>

				<form method='post' action='/auth/callback'>
					<DialogContent>

						<TextField
							autoFocus
							id='username'
							label={intl.formatMessage({
								id             : 'label.username',
								defaultMessage : 'Username'
							})}
							variant='outlined'
							margin='normal'
							name='username'
							required
							fullWidth
							onChange={(e) => setTextChanged(true)}
						/>
						<TextField
							id='password'
							label={intl.formatMessage({
								id             : 'label.password',
								defaultMessage : 'Password'
							})}
							variant='outlined'
							margin='normal'
							name='password'
							type='password'
							required
							fullWidth
							onChange={(e) => setTextChanged(true)}
						/>
						{
							showError &&
							<div className={classes.error}>
								<span>{ intl.formatMessage({ id: 'error.invalidLoginCredential', defaultMessage: 'Wrong username or/and password' })}</span>
							</div>
						}
					</DialogContent>

					<DialogActions>
						<Button
							variant='contained'
							color='primary'
							type='submit'
						>
							<FormattedMessage
								id='label.login'
								defaultMessage='Login'
							/>
						</Button>
					</DialogActions>
				</form>

				{ !isElectron() &&
					<CookieConsent buttonText={intl.formatMessage({
						id             : 'room.consentUnderstand',
						defaultMessage : 'I understand'
					})}
					>
						<FormattedMessage
							id='room.cookieConsent'
							defaultMessage='This website uses cookies to enhance the user experience'
						/>
					</CookieConsent>
				}
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) =>
{
	return {
		loginError : state.error.loginError,
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setLoginError : (flag) =>
		{
			dispatch(errorActions.setLoginError(flag));
		}
	};
};

ChooseRoom.propTypes =
{
	classes : PropTypes.object.isRequired
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ChooseRoom)));
