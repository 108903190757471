const initialState =
{
	loginError : false
};

const error = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_LOGIN_ERROR':
		{
			const { flag } = action.payload;

			return { ...state, loginError: flag };
		}

		default:
			return state;
	}
};

export default error;
