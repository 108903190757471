import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import marked from 'marked';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import * as meActions from '../../../actions/meActions';

const linkRenderer = new marked.Renderer();

linkRenderer.link = (href, title, text) =>
{
	title = title ? title : href;
	text = text ? text : href;

	return `<a target='_blank' href='${ href }' title='${ title }'>${ text }</a>`;
};

const styles = (theme) =>
	({
		root :
		{
			display      : 'flex',
			marginBottom : theme.spacing(1),
			padding      : theme.spacing(1),
			flexShrink   : 0
		},
		selfMessage :
		{
			marginLeft : 'auto'
		},
		remoteMessage :
		{
			marginRight : 'auto'
		},
		text :
		{
			'& p' :
			{
				margin : 0
			}
		},
		content :
		{
			marginLeft : theme.spacing(1)
		},
		avatar :
		{
			borderRadius : '50%',
			height       : '2rem',
			alignSelf    : 'center'
		},
		here :
		{
			textDecoration: 'underline',
			color: 'blue',
			cursor: 'pointer'
		},
		videoName :
		{
			color: 'blue',
			fontWeight: 600
		}
	});

const Message = (props) =>
{
	const intl = useIntl();

	const {
		self,
		picture,
		text,
		time,
		name,
		action,
		classes,
		sharedVideoSet
	} = props;

	const openVideo = () => {
		sharedVideoSet(action.video)
	}

	return (
		<Paper
			className={classnames(
				classes.root,
				self ? classes.selfMessage : classes.remoteMessage
			)}
		>
			<img alt='Avatar' className={classes.avatar} src={picture} />
			<div className={classes.content}>
			{
				action ?
					<Typography
					 className={classes.text}
					 variant='subtitle1'
				 >
				 	<span>{action.posterName}</span>
					<span>
						{
							intl.formatMessage({
								id             : 'label.shareVideoClick',
								defaultMessage : ' shared a video. Please click '
							})
						}
					</span>
					<span className={classes.here} onClick={() => openVideo()} >
						{
							intl.formatMessage({
								id             : 'label.here',
								defaultMessage : 'here'
							})
						}
					</span>
					<span>
					{
						intl.formatMessage({
							id             : 'label.toSeeVideo',
							defaultMessage : ' to see video '
						})
					}
					</span>
					<span className={classes.videoName}>{`${action.video.name}.`}</span>
				 </Typography>
				 :
				 <Typography
 					className={classes.text}
 					variant='subtitle1'
 					// eslint-disable-next-line react/no-danger
 					dangerouslySetInnerHTML={{ __html : DOMPurify.sanitize(
 						marked.parse(
 							text,
 							{ renderer: linkRenderer }
 						),
 						{
 							ALLOWED_TAGS : [ 'a' ],
 							ALLOWED_ATTR : [ 'href', 'target', 'title' ]
 						}
 					) }}
 				/>
			}

				<Typography variant='caption'>
					{ self ?
						intl.formatMessage({
							id             : 'room.me',
							defaultMessage : 'Me'
						})
						:
						name
					} - {time}
				</Typography>
			</div>
		</Paper>
	);
};

Message.propTypes =
{
	self    : PropTypes.bool,
	picture : PropTypes.string,
	text    : PropTypes.string,
	time    : PropTypes.object,
	name    : PropTypes.string,
	classes : PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) =>
	({
		sharedVideoSet : (video) =>
		{
			dispatch(meActions.sharedVideo(video))
		}
	});

export default connect(
		null,
		mapDispatchToProps,
		null,
		{}
	)(withStyles(styles)(Message));
