export default [
  {
			id:	1,
			name: 'Episode 1',
			description: 'Psykologisk immunforsvar',
			sources: 'https://player.vimeo.com/video/292276562'
		},

		{
			id:	2,
			name: 'Episode 2',
			description: 'Tankevirus',
			sources: 'https://player.vimeo.com/video/292276816'
		},

		{
			id:	3,
			name: 'Episode 3',
			description: 'PSYKOLOGISKE VITAMINER',
			sources: 'https://player.vimeo.com/video/292277150'
		}
]
