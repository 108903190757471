import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  isMobile
} from "react-device-detect";

const styles = (theme) =>
	({
    modalContainer: {
			display: 'flex',
	    alignItems: 'center',
	    justifyContent: 'center',
      zIndex: '1200 !important'
		},
		modal :
		{
			backgroundColor: 'rgba(255, 255, 255, 1)',
			width: '640px',
		  border: 'none',
			outline: 'none'
		},
		btnClose :
		{
       marginLeft: '6px'
		},
		modalHeader : {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '0 1em'
		},
    btnGroup : {
      display: 'flex',
      alignItems: 'center'
    }
	});

const VimeoModal = (props) =>
{
  const { onClose, open, video, classes, closeParticipants } = props

	const intl = useIntl();

	return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalContainer}
      disableBackdropClick={true}
      disableEnforceFocus={true}
    >
      <div className={classes.modal}>
        <div className={classes.modalHeader}>
          <h2 id="simple-modal-title">{video.name}</h2>
          <div className={classes.btnGroup}>
            {
              closeParticipants &&
              <Button
                variant="contained"
                color="primary"
                size='small'
                onClick={() => closeParticipants()}
              >
                <FormattedMessage
                  id='label.closeParticipantsVideo'
                  defaultMessage='Close participants video'
                />
              </Button>
            }
            <Button
              variant="contained"
              color="primary"
              size='small'
              className={classes.btnClose}
              onClick={() => onClose()}
            >
              {
                closeParticipants ?
                  <FormattedMessage
                    id='label.closeMyVideo'
                    defaultMessage='Close only your video'
                  />
                :
                <FormattedMessage
                  id='label.closeMyVideoNoneLogin'
                  defaultMessage='Close video'
                />
              }
            </Button>
            {
              /**
              <img src='https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png' className={classes.btnClose} onClick={() => onClose()}/>
              */
            }
          </div>
        </div>
        {
          isMobile ?
            <iframe src={`${video.sources}?autoplay=1`} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            :
            <iframe src={`${video.sources}?autoplay=1`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        }
      </div>
    </Modal>
	);
};

export default withStyles(styles)(VimeoModal)
